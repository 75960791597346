import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { SearchFilterParamNames } from "@find-hotels-app/core/services/search-services/services/bi/search-filter-parser/models/search-filter-param-names";
import { SearchFilterParserService } from "@find-hotels-app/core/services/search-services/services/bi/search-filter-parser/search-filter-parser.service";
import { ROOM_SEARCH_ERROR_CODE } from "@find-hotels-app/core/services/select-room-services/contants/room-errors.constants";
import { RouteNames } from "@find-hotels-app/shared/models/route-names.enum";
import { IIbrMealPlanCodeAndQuantity, ISearchCriteria } from "@find-hotels-app/shared/models/search-criteria";
import { NonRoomInventoryProduct } from "@find-hotels-app/shared/models/shopping-cart.model";
import { ShoppingCartService } from "@find-hotels-app/shared/services/session-state/shopping-cart.service";
import { StorageService } from "@find-hotels-app/shared/services/storage/storage-service";
import { LogService } from "@ihg/logging";
import { LineItem } from "@ihg/shopping";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { Action } from "@ngrx/store";
import { RedirectService } from "@pages/select-room-rate/services/redirect/redirect.service";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { SortFilterCriteria } from "@shared/models/sort-filter-criteria";
import { NoopAction } from "@shared/services/navigation/actions/noop-action";
import { SearchResultMapReloadAction } from "@shared/services/navigation/actions/search-result-map-reload-action";
import { SelectLineItemsAction } from "@shared/services/navigation/actions/select-line-items-action";
import { SkipRedirectToPayAction } from "@shared/services/navigation/actions/skip-enhance-redirect-action";
import { UpdateCorporateIdAction } from "@shared/services/navigation/actions/update-corporate-id-action";
import { RouterFacadeService } from "@core/services/router-facade-service/router.facade";
import { CurrencyStore } from "@core/stores/currency/currency.store";
import { SearchCriteriaStore } from "@core/stores/search/search-criteria.store";
import { SortFilterCriteriaStore } from "@core/stores/search/sort-filter-criteria.store";
import { RedirectToCRRAction } from "../actions/redirect-to-crr-action";
import { SelectEnhanceStayAction } from "../actions/select-enhance-stay-action";
import { SelectHotelAction } from "../actions/select-hotel-action";
import { SelectPriceTypeAction } from "../actions/select-search-result-price-type-action";
import { ViewNavigationAction } from "../actions/view-navigation-action";
import { ViewNavigationActionType } from "../actions/view-navigation-action.enum";
import { ViewNavigationModel } from "../models/view-navigation-model";

@Injectable({
  providedIn: "root",
})
export class ViewNavigationEffects {
  static CLASS_NAME = "ViewNavigationEffects";

  routerNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.VIEW_NAVIGATION_NAVIGATE_TO_NEXT_STATE]),
        tap((action: ViewNavigationAction) => {
          const params: ViewNavigationModel = action.navigationParams;
          this.router.navigateByUrl(this.router.createUrlTree([params.path], { queryParams: params.params }));
        })
      ),
    { dispatch: false }
  );

  updateCorporateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.UPDATE_CORPORATE_ID]),
      withLatestFrom(this.routerFacadeService.queryParams$, this.routerFacadeService.route$),
      map(([action, params, route]: [UpdateCorporateIdAction, Params, string]) => {
        const profileCorporateId = action.corporateId;
        const queryParamsHasCorporateId: boolean = params[SearchFilterParamNames.CORPORATE_ID] !== undefined;
        if (!queryParamsHasCorporateId) {
          const { preferredRateCodes, corporateId } = this.searchCriteriaStore.searchCriteria;
          const updateNeeded = !(preferredRateCodes?.length > 0 || corporateId?.length > 0);
          if (updateNeeded) {
            return new ViewNavigationAction({ path: route, params: { ...params, qCpid: profileCorporateId } });
          }
        }
        return new NoopAction();
      })
    )
  );

  selectEnhanceStay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_ENHANCE_STAY_REDIRECT]),
        withLatestFrom(this.currencyStore.currentCurrency$),
        tap(([action, currentCurrency]) => {
          const { productList, upsellProduct, searchCriteria, redirectToEys, availOffer } =
            action as SelectEnhanceStayAction;
          const BPRProduct = productList.find((x) => x.isBonusPointsRate);
          const rateCode = BPRProduct?.ratePlanCode ?? availOffer.rate.code;
          const roomCode = upsellProduct?.productCode ?? availOffer.room.inventoryTypeCode;

          const nonRoomInventoryList: NonRoomInventoryProduct[] = productList
            .filter((x) => x.isBonusPointsRate === false)
            .map((product) => ({
              parentId: 1,
              productCode: product.productCode,
              name: product.title,
              productTypeCode: product.productTypeCode,
              quantity: product.quantity,
              ratePlanCode: product.ratePlanCode,
              ratePlanName: product.rateName,
              mnemonic: availOffer.hotel.mnemonic,
              checkIn: availOffer.checkIn,
              checkOut: availOffer.checkOut,
              guestCounts: availOffer.room.guestCounts,
            }));
          const override = rateCode !== availOffer.rate.code || roomCode !== availOffer.room.inventoryTypeCode;
          const lineItem = override
            ? this.shoppingCartService.overrideAvailOfferToLineItem(1, availOffer, rateCode, roomCode)
            : this.shoppingCartService.availOfferToLineItem(1, availOffer);
          const nonRoomItems = this.shoppingCartService.buildCrossSellProducts(nonRoomInventoryList);

          const upsellProductValue = upsellProduct
            ? {
                name: upsellProduct.productName,
                pricingFrequency: upsellProduct.pricingFrequency,
                productCode: upsellProduct.productCode,
                upsellReason: upsellProduct.upsellReason,
                priceDifference: upsellProduct.cashBeforeTax,
                taxDifference: upsellProduct.tax,
              }
            : undefined;

          const lineItems = [lineItem, ...nonRoomItems];

          this.shoppingCartService.saveLineItems(lineItems, {
            currency: currentCurrency,
            backPage: "eys",
            upsellProduct: upsellProductValue,
            showGuestDetailsBanner: false,
          });
          if (redirectToEys) {
            this.shoppingCartService.createShoppingCart(
              searchCriteria,
              new LineItem(lineItem),
              productList.map((product) => ({
                productCode: product.productCode,
                productTypeCode: product.productTypeCode,
                name: product.title,
                pricingFrequency: product.pricingFrequency,
                pricingMethod: product.pricingMethod,
                quantity: product.quantity,
                baseAmount: product.price,
                ratePlanCode: product.ratePlanCode,
                totalTax: product.tax,
                totalFee: product.serviceCharge,
                ratePlanName: product.rateName,
                cashBeforeTax: product.priceBeforeTax,
                purchaseLimit: product.purchaseLimit,
              })),
              upsellProductValue,
              true,
              currentCurrency
            );
            this.redirectService.redirectToStayMgmt(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
          } else {
            this.redirectService.redirectToPay();
          }
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToPay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_PAY]),
        withLatestFrom(this.currencyStore.currentCurrency$),
        tap(([action, currentCurrency]) => {
          const { availOffer } = action as SkipRedirectToPayAction;
          const lineItem = this.shoppingCartService.availOfferToLineItem(1, availOffer);
          this.shoppingCartService.saveLineItems([lineItem], {
            currency: currentCurrency,
            upsellProduct: undefined,
            backPage: "eys",
            showGuestDetailsBanner: false,
          });
          this.redirectService.redirectToPay();
        })
      ),
    { dispatch: false }
  );

  selectLineItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_ROOM_RATE_REDIRECT]),
        withLatestFrom(
          this.searchCriteriaStore.searchCriteria$,
          this.sortFilterCriteriaStore.sortFilterCriteria$,
          this.currencyStore.currentCurrency$,
          this.routerFacadeService.getUrl$
        ),
        tap(
          ([action, searchCriteria, sortFilterCriteria, currentCurrency, url]: [
            SelectLineItemsAction,
            ISearchCriteria,
            SortFilterCriteria,
            string,
            string,
          ]) => {
            const { lineItems, showGuestDetailsBanner } = action;
            if (lineItems.length > 1) {
              this.shoppingCartService.saveLineItems(lineItems, {
                currency: currentCurrency,
                backPage: "crr",
                upsellProduct: undefined,
                showGuestDetailsBanner: showGuestDetailsBanner,
              });
              this.redirectService.redirectToPay();
              return;
            }
            const redirectToStay = url.includes(RouteNames.SELECT_ROOM_RATE_CHANGE);
            const lineItem = lineItems[0];
            const searchCriteriaWithRateAndRoom = <ISearchCriteria>{
              ...searchCriteria,
              roomCode: lineItem.mainProduct.inventoryTypeCode,
              rateCode: lineItem.rateCode,
              roomProductCode: lineItem.mainProduct.productCode,
              rooms: [
                {
                  numberOfAdults: lineItem.adults,
                  numberOfChildren: lineItem.children,
                  numberOfRooms: lineItem.mainProduct.quantity,
                  childrenAges: lineItem.childrenAges,
                },
              ],
            };

            const isIncludeMealProducts = lineItem.nonMainProduct.length > 0;
            if (isIncludeMealProducts) {
              const mealPlans: IIbrMealPlanCodeAndQuantity[] = [];
              lineItem.nonMainProduct.forEach((product) => {
                mealPlans.push({
                  inventoryTypeCode: product.inventoryTypeCode,
                  quantity: product.quantity,
                  productCode: product.productCode,
                });
              });
              searchCriteriaWithRateAndRoom.ibrMealPlanCodesAndQuantities = mealPlans;
            }

            this.shoppingCartService.saveLineItems([lineItem], {
              currency: currentCurrency,
              upsellProduct: undefined,
              backPage: "crr",
              showGuestDetailsBanner: false,
            });

            const isEYSRedirect = redirectToStay === false;

            if (isEYSRedirect) {
              const params = this.searchFilterParserService.getParams(
                searchCriteriaWithRateAndRoom,
                sortFilterCriteria
              );
              this.redirectService.redirectToEYS(params);
            } else {
              this.shoppingCartService.createShoppingCart(
                searchCriteriaWithRateAndRoom,
                lineItem,
                [],
                undefined,
                false,
                currentCurrency
              );
              this.storageService.setSelectedRoomRate(searchCriteriaWithRateAndRoom.confirmationNumber);
              this.redirectService.redirectToStayMgmt(
                searchCriteriaWithRateAndRoom.confirmationNumber,
                searchCriteriaWithRateAndRoom.guestLastName
              );
            }
          }
        ),
        catchError((error: Error) => {
          this.logService.error("Error retrieving NRI information.", ViewNavigationEffects.CLASS_NAME, error);
          this.redirectService.redirectToPay();
          return of(false);
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToModify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_MODIFY]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$),
        tap(([, searchCriteria]) => {
          this.redirectService.redirectToStayMgmt(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToResCon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_RESCON]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$),
        map(([, searchCriteria]) => {
          this.redirectService.redirectToResConPage(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
        })
      ),
    { dispatch: false }
  );

  navigatedSuccessfullyEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(() => {
          this.logService.postLogs();
        })
      ),
    { dispatch: false }
  );

  loadSelectAHotelPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType.SELECT_HOTEL_REDIRECT),
        withLatestFrom(this.routerFacadeService.queryParams$),
        tap(([action, params]: [SelectHotelAction, Params]) => {
          let showNoRoomAvailError = false;
          const queryParams = params;
          const hotelMnemonic = queryParams[SearchFilterParamNames.MNEMONIC] ?? null;
          if (hotelMnemonic) {
            queryParams[SearchFilterParamNames.PREFERRED_MNEMONIC] = hotelMnemonic;
            delete queryParams[SearchFilterParamNames.MNEMONIC];
          }
          if (action.errorCode === ROOM_SEARCH_ERROR_CODE.INVALID_CAPACITY_OF_ROOMS) {
            showNoRoomAvailError = true;
          }
          if (action.removeRate) {
            queryParams[SearchFilterParamNames.PREFERRED_RATES] = null;
          }
          this.router.navigate([RouteNames.SEARCH_RESULT], {
            queryParams,
            state: { showNoRoomAvailError: showNoRoomAvailError },
          });
        })
      ),
    { dispatch: false }
  );

  searchResultPriceType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_PRICE_TYPE]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const priceType = (action as SelectPriceTypeAction).priceType;
          const preferredRate = (action as SelectPriceTypeAction).preferredRate;
          const searchCriteriaWithPriceType = {
            ...searchCriteria,
            preferredRateCodes: [preferredRate],
          };
          const sortCriteriaWithPriceType = {
            ...sortFilterCriteria,
            priceType: priceType,
          };

          const params = this.searchFilterParserService.getParams(
            searchCriteriaWithPriceType,
            sortCriteriaWithPriceType
          );
          this.redirectService.redirectToSearchResult(params);
        })
      ),
    { dispatch: false }
  );

  searchResultMapReload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SEARCH_RESULT_MAP_RELOAD]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const destination = (action as SearchResultMapReloadAction).destination;
          const searchCriteriaWithPriceType = {
            ...searchCriteria,
            destination: destination,
          };
          const params = this.searchFilterParserService.getParams(searchCriteriaWithPriceType, sortFilterCriteria);
          this.redirectService.redirectToSearchResult(params);
        })
      ),
    { dispatch: false }
  );

  redirectToCRR$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.REDIRECT_TO_CRR]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const hotelCode = (action as RedirectToCRRAction).hotelCode;
          const checkInDate = (action as RedirectToCRRAction).checkInDate;
          const checkOutDate = (action as RedirectToCRRAction).checkOutDate;
          const removeRate = (action as RedirectToCRRAction).removeRate ?? false;
          if (hotelCode) {
            searchCriteria.hotelMnemonic = hotelCode;
          }
          if (checkInDate) {
            searchCriteria.checkInDate = checkInDate;
          }
          if (checkOutDate) {
            searchCriteria.checkOutDate = checkOutDate;
          }
          if (removeRate) {
            searchCriteria.preferredRateCodes = [];
          }
          const params = this.searchFilterParserService.getParams(searchCriteria, sortFilterCriteria);
          this.redirectService.redirectToCRR(params);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<ViewNavigationAction>,
    private logService: LogService,
    private redirectService: RedirectService,
    public router: Router,
    public storageService: StorageService,
    public shoppingCartService: ShoppingCartService,
    public searchFilterParserService: SearchFilterParserService,
    public currencyStore: CurrencyStore,
    private routerFacadeService: RouterFacadeService,
    private searchCriteriaStore: SearchCriteriaStore,
    private sortFilterCriteriaStore: SortFilterCriteriaStore
  ) {}
}
